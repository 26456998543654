<!-- Supplier -->
<template>
  <div class="card card-custom" v-loading="loading">
    <div class="card-body p-0">
      <!--begin: Suppliers-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Supplier Body-->
        <div class="row justify-content-center">
          <div class="col-xl-12">
            <!--begin: Wizard Form-->
            <el-form ref="form" :model="form" auto-complete="false">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h3 class="mb-10 font-weight-bold text-dark">
                  <i class="flaticon-rotate settingicon"></i> {{ title_text }}
                </h3>
                <div class="row">
                  <div class="col-xl-9">
                    <div class="row">
                      <div class="col-xl-3">
                        <el-form-item label="Supplier ID" prop="supplier_Id">
                          <el-input
                            v-model="form.supplier_Id"
                            disabled
                            auto-complete="nope"
                            :class="
                              form.errors.has('supplier_Id')
                                ? 'error required'
                                : ''
                            "
                          ></el-input>
                          <span
                            class="form-err"
                            v-if="form.errors.has('supplier_Id')"
                            >{{ form.errors.get("supplier_Id") }}</span
                          >
                        </el-form-item>
                      </div>
                      <div class="col-xl-3">
                        <el-form-item label="Company Name" prop="company_name">
                          <el-input
                            v-model="form.company_name"
                            @input.native="capitalize"
                            auto-complete="nope"
                            :class="
                              form.errors.has('company_name')
                                ? 'error required'
                                : 'required'
                            "
                          ></el-input>
                          <span
                            class="form-err"
                            v-if="form.errors.has('company_name')"
                            >{{ form.errors.get("company_name") }}</span
                          >
                        </el-form-item>
                      </div>
                      <div class="col-xl-3">
                        <el-form-item label="First Name" prop="first_name">
                          <el-input
                            v-model="form.first_name"
                            @input.native="capitalize"
                            auto-complete="nope"
                            :class="
                              form.errors.has('first_name')
                                ? 'error required'
                                : 'required'
                            "
                          ></el-input>
                          <span
                            class="form-err"
                            v-if="form.errors.has('first_name')"
                            >{{ form.errors.get("first_name") }}</span
                          >
                        </el-form-item>
                      </div>
                      <div class="col-xl-3">
                        <el-form-item label="Last Name" prop="last_name">
                          <el-input
                            v-model="form.last_name"
                            @input.native="capitalize"
                            auto-complete="nope"
                          ></el-input>
                          <span
                            class="form-err"
                            v-if="form.errors.has('last_name')"
                            >{{ form.errors.get("last_name") }}</span
                          >
                        </el-form-item>
                      </div>
                      <div class="col-xl-3">
                        <el-form-item label="Email" prop="email_address">
                          <el-input
                            v-model="form.email_address"
                            auto-complete="nope"
                          ></el-input>
                          <span
                            class="form-err"
                            v-if="form.errors.has('email_address')"
                            >{{ form.errors.get("email_address") }}</span
                          >
                        </el-form-item>
                      </div>
                      <div class="col-xl-3">
                        <el-form-item label="Phone I" prop="mobile">
                          <el-input
                            v-model="form.mobile"
                            auto-complete="nope"
                            :class="
                              form.errors.has('mobile')
                                ? 'phone_check_error phone_check'
                                : 'phone_check'
                            "
                            @keyup.native="mobileNumberValidation"                            
                          >
                            <template slot="prepend">+1</template>
                          </el-input>
                          <span class="form-err" v-if="!phone_error">{{
                            form.errors.get("mobile")
                          }}</span>
                          <span class="form-err" else>{{ phone_error }}</span>
                        </el-form-item>
                      </div>
                      <div class="col-xl-3">
                        <el-form-item label="Phone II" prop="phone">
                          <el-input
                            v-model="form.phone"
                            auto-complete="nope"
                            @keyup.native="phoneNumberValidation"  
                            class="phone_2"
                          >
                            <template slot="prepend">+1</template></el-input
                          >
                          <span class="form-err" v-if="!phone_error1">{{
                            form.errors.get("phone")
                          }}</span>
                          <span class="form-err" else>{{ phone_error1 }}</span>
                        </el-form-item>
                      </div>
                      <div class="col-xl-3">
                        <el-form-item label="Fax" prop="fax">
                          <el-input
                            v-model="form.fax"
                            auto-complete="nope"
                          ></el-input>
                          <span
                            class="form-err"
                            v-if="form.errors.has('fax')"
                            >{{ form.errors.get("fax") }}</span
                          >
                        </el-form-item>
                      </div>
                      <div class="col-xl-3">
                        <el-form-item label="Website" prop="website">
                          <el-input
                            v-model="form.website"
                            auto-complete="nope"
                          ></el-input>
                          <span
                            class="form-err"
                            v-if="form.errors.has('website')"
                            >{{ form.errors.get("website") }}</span
                          >
                        </el-form-item>
                      </div>
                      <div
                        class="col-xl-3"
                        v-if="this.$route.params.supplierId"
                      >
                        <el-form-item label="Terms" prop="term_id">
                          <el-select
                            v-model="form.term_id"
                            placeholder="Select"
                            clearable
                          >
                            <el-option class="add-container" value="">
                              <a class="add-drop-btn" @click="createTerms">
                                <i class="el-icon-circle-plus"></i>
                                Add Term
                              </a>
                            </el-option>
                            <el-option
                              v-for="(term, index) in terms"
                              :key="index"
                              :label="term.term_name"
                              :value="term.id"
                              auto-complete="off"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="col-xl-3">
                        <el-form-item label="Category" prop="category_id">
                          <el-select
                            :value="treeLabel"
                            placeholder="select category"
                            ref="selectTree1"
                            :class="
                              form.errors.has('category_id')
                                ? 'error required'
                                : 'required'
                            "
                          >
                            <el-option class="add-container" value="">
                              <a class="add-drop-btn" @click="addCategorys">
                                <i class="el-icon-circle-plus"></i>
                                Add
                              </a>
                            </el-option>
                            <el-option
                              :value="treeLabel"
                              :label="treeLabel"
                              class="tree_option"
                            >
                              <el-tree
                                class="filter-tree"
                                :data="category"
                                :props="defaultProps"
                                node-key="id"
                                :expand-on-click-node="false"
                                @current-change="SelectCategory"
                                :default-expanded-keys="expand"
                                :highlight-current="true"
                                @node-click="handleNodeClick"
                                ref="tree"
                              >
                                <span
                                  class="custom-tree-node"
                                  slot-scope="{ data }"
                                >
                                  <span class="icon-folder">
                                    <i
                                      class="el-icon-folder el-fw"
                                      aria-hidden="true"
                                    ></i>
                                    <span class="icon-folder_text">
                                      {{ data.label }}</span
                                    >
                                  </span>
                                </span>
                              </el-tree>
                            </el-option>
                          </el-select>
                          <span
                            class="form-err"
                            v-if="form.errors.has('category_id')"
                            >Category field is required</span
                          >
                        </el-form-item>
                      </div>

                      <div class="col-xl-3">
                        <el-form-item label="Status" prop="active">
                          <el-select v-model="form.active">
                            <el-option
                              v-for="(option, index) in options"
                              :key="index"
                              :label="option.text"
                              :value="option.id"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="form-group">
                          <div class="field text-center">
                            <div class="preview-container">
                              <img v-if="img_preview" :src="img_preview" />
                            </div>
                          </div>
                          <div class="field text-center">
                            <div class="upload-btn-container">
                              <a href="#" class="add-logo-btn">
                                <span v-if="image_btn == 0">Add Photo</span>
                                <span v-else>Edit Photo</span>
                                <input
                                  type="file"
                                  @change="onFileChange"
                                  accept="image/*"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-2">
                    <el-form-item
                      label="Regular Percentage"
                      prop="regular_percentage"
                    >
                      <el-input
                        v-model="form.regular_percentage"
                        auto-complete="nope"
                        :min="0"
                        :max="100"
                        suffix-icon="fas fa-percent"
                        onkeypress="return event.keyCode>=48&&event.keyCode<=57"
                        maxlength="3"
                        class="percentage_input"
                      ></el-input>
                      <span
                        class="form-err"
                        v-if="form.errors.has('regular_percentage')"
                        >{{ form.errors.get("regular_percentage") }}</span
                      >
                    </el-form-item>
                  </div>
                  <div class="col-xl-2">
                    <el-form-item
                      label="Contractor Off"
                      prop="contractor_percentage"
                    >
                      <el-input
                        v-model="form.contractor_percentage"
                        auto-complete="nope"
                        :min="0"
                        :max="100"
                        suffix-icon="fas fa-percent"
                        onkeypress="return event.keyCode>=48&&event.keyCode<=57"
                        maxlength="3"
                        class="percentage_input"
                      ></el-input>
                      <span
                        class="form-err"
                        v-if="form.errors.has('contractor_percentage')"
                        >{{ form.errors.get("contractor_percentage") }}</span
                      >
                    </el-form-item>
                  </div>
                  <div class="col-xl-2">
                    <el-form-item
                      label="Dealer Margin"
                      prop="dealer_percentage"
                    >
                      <el-input
                        v-model="form.dealer_percentage"
                        auto-complete="nope"
                        :min="0"
                        :max="100"
                        suffix-icon="fas fa-percent"
                        onkeypress="return event.keyCode>=48&&event.keyCode<=57"
                        maxlength="3"
                        class="percentage_input"
                      ></el-input>
                      <span
                        class="form-err"
                        v-if="form.errors.has('dealer_percentage')"
                        >{{ form.errors.get("dealer_percentage") }}</span
                      >
                    </el-form-item>
                  </div>
                  <div class="col-xl-2">
                    <el-form-item
                      label="Wholesale Off"
                      prop="wholesale_percentage"
                    >
                      <el-input
                        v-model="form.wholesale_percentage"
                        auto-complete="nope"
                        :min="0"
                        :max="100"
                        suffix-icon="fas fa-percent"
                        onkeypress="return event.keyCode>=48&&event.keyCode<=57"
                        maxlength="3"
                        class="percentage_input"
                      ></el-input>
                      <span
                        class="form-err"
                        v-if="form.errors.has('wholesale_percentage')"
                        >{{ form.errors.get("wholesale_percentage") }}</span
                      >
                    </el-form-item>
                  </div>
                  <div class="col-xl-2">
                    <el-form-item
                      label="Inside Percentage"
                      prop="inside_percentage"
                    >
                      <el-input
                        v-model="form.inside_percentage"
                        auto-complete="nope"
                        :min="0"
                        :max="100"
                        suffix-icon="fas fa-percent"
                        onkeypress="return event.keyCode>=48&&event.keyCode<=57"
                        maxlength="3"
                        class="percentage_input"
                      ></el-input>
                      <span
                        class="form-err"
                        v-if="form.errors.has('inside_percentage')"
                        >{{ form.errors.get("inside_percentage") }}</span
                      >
                    </el-form-item>
                  </div>
                  <div class="col-xl-12">
                    <div class="row">
                      <div class="col-xl-12">
                        <h4>Address</h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <el-form-item label="Address" prop="street">
                      <vue-google-autocomplete
                        ref="origin"
                        v-model="form.street"
                        id="map"
                        classname="form-control el-input__inner"
                        name="street"
                        placeholder="Enter your Address"
                        v-on:placechanged="getAddressData"
                        country="ca"
                      >
                      </vue-google-autocomplete>

                      <span class="form-err" v-if="form.errors.has('street')">{{
                        form.errors.get("street")
                      }}</span>
                    </el-form-item>
                  </div>
                  <div class="col-xl-2">
                    <el-form-item label="Unit No" prop="unit_no">
                      <el-input
                        v-model="form.unit_no"
                        @input.native="capitalize"
                        auto-complete="nope"
                      ></el-input>
                      <span
                        class="form-err"
                        v-if="form.errors.has('unit_no')"
                        >{{ form.errors.get("unit_no") }}</span
                      >
                    </el-form-item>
                  </div>
                  <div class="col-xl-3">
                    <el-form-item label="Province" prop="state_id">
                      <el-select
                        filterable
                        ref="state_id"
                        v-model="form.state_id"
                        @change="getCityListByStateId"
                        clearable
                      >
                        <el-option
                          v-for="(state, index) in state_list"
                          :key="index"
                          :label="state.state_name"
                          :value="state.state_id"
                        >
                        </el-option>
                      </el-select>
                      <span class="form-err" v-if="form.errors.has('state_id')">
                        {{ form.errors.get("state_id") }}
                      </span>
                    </el-form-item>
                  </div>
                  <div class="col-xl-3">
                    <el-form-item label="City" prop="city_id">
                      <el-select
                        ref="city_id"
                        v-model="form.city_id"
                        v-loading="cityLoader"
                        clearable
                        filterable=""
                      >
                        <el-option
                          v-for="(city, index) in city_list"
                          :key="index"
                          :label="city.city_name"
                          :value="city.city_id"
                        >
                        </el-option>
                        <el-option class="add-container" value="">
                          <a class="add-drop-btn" @click="CreateCity()">
                            <i class="el-icon-circle-plus"></i>
                            Add City
                          </a>
                        </el-option>
                      </el-select>
                      <span class="form-err" v-if="form.errors.has('city_id')">
                        {{ form.errors.get("city_id") }}
                      </span>
                    </el-form-item>
                  </div>
                  <div class="col-xl-2">
                    <el-form-item label="Postal Code" prop="postal_code">
                      <el-input
                        v-model="form.postal_code"
                        auto-complete="nope"
                        placeholder="M1S 3A7"
                        @change="validPostal(form.postal_code)"
                      ></el-input>
                      <span
                        class="form-err"
                        v-if="form.errors.has('postal_code')"
                        >{{ form.errors.get("postal_code") }}</span
                      >
                    </el-form-item>
                  </div>
                </div>
              </div>

              <!--begin: Supplier Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <a @click="createSupplier" class="text-white">
                  <div class="btn btn-primary font-weight-bold text-uppercase">
                    {{ submit_btn_text }}
                  </div>
                </a>
              </div>
              <!--end: Suppliers Actions -->
            </el-form>
            <!--end: Suppliers Form-->
          </div>
        </div>
        <!--end: Suppliers Body-->
      </div>
    </div>
    <!--end: Suppliers-->
  </div>
</template>



<style>
.settingicon {
  font-size: 20px;
}
select.select2 {
  opacity: 1;
}
.preview-container img {
  max-width: 200px;
}
.add-logo-btn {
  margin-left: 40px;
}
.phone_check_error .el-input-group__prepend {
  border-top: 1px solid red;
  border-bottom: 1px solid red;
}
.phone_check .el-input-group__prepend {
  border-left: 2px solid red;
}
.phone_check_error .el-input__inner {
  border-right: 1px solid red;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
}
.el-input-group__prepend {
  padding: 0px 8px !important;
}
.phone_check .el-input__inner {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.phone_2 .el-input__inner {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.percentage_input {
  width: 75%;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/core/services/api.form.services";
import {
  getCityList,
  getStateList,
  getCityListByStateId,
  getStateByPrefix,
  getCityByName,
} from "@/api/common";
import {
  getSupplierDetails,
  getSupplierTerms,
  generateSupplierId,
} from "@/api/supplier";
import {
  createProductId,
  getAllcategoryList,
  getCategoryTree,
} from "@/api/product";
import CreateTerm from "@/components/CreateTerm";
import { globalEvents } from "@/utils/globalEvents";
import addCategory from "@/components/CreateCategory/addCategory";
import CreateCity from "@/components/DropDown/addcity";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "add-supplier",
  data() {
    return {
      loading: false,
      error: null,
      form: new Form({
        supplier_Id: null,
        company_name: null,
        first_name: null,
        last_name: null,
        email_address: null,
        phone: null,
        mobile: null,
        fax: null,
        website: null,
        unit_no: null,
        street: null,
        city_id: null,
        state_id: 8,
        postal_code: null,
        supplier_image: null,
        active: 1,
        term_id: null,
        category_id: null,
        regular_percentage: 0,
        contractor_percentage: 0,
        dealer_percentage: 0,
        wholesale_percentage: 0,
        inside_percentage: 0,
      }),
      treeLabel: null,
      expand: [],
      category: [],
      terms: [],
      city_list: [],
      state_list: [],
      category_list: [],
      img_preview: "/./media/bg/profile-img.png",
      logo_btn_text: null,
      submit_btn_text: "Create Supplier",
      title_text: "Create Supplier",
      api_url: "/supplier/create",
      response_msg: "Supplier created successfully.",
      cityLoader: false,
      options: [
        { id: 1, text: "Active" },
        { id: 0, text: "In Active" },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      phone_error: "",
      phone_error1: "",
      image_btn: 0,
    };
  },
  components: {
    VueGoogleAutocomplete,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Suppliers", route: "/supplier/list" },
      { title: "Add/Edit Supplier" },
    ]);
    globalEvents.$on("addCategory", (cat) => {
      if (cat) {
        this.getCategory();
        this.form.category_id = cat.id;
        this.expand = [cat.id];
        this.treeLabel = cat.label;
      }
    });
    globalEvents.$on("cityname", (type) => {
      if (type) {
        this.city_list.push(type);
        this.form.city_id = type.city_id;
      }
    });
  },
  methods: {
    phoneNumberValidation() {
      this.phoneValue = this.form.phone.replace(/[^0-9]+/g, "");
      this.form.phone = this.phoneValue
        .replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "$1-$2-$3")
        .slice(0, 12);     
    },
    phoneNumberValidation1(mobile) {
      this.form.mobile = "";
      var x = mobile.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.form.mobile = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + "-" + (x[3] ? "" + x[3] : "");
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
      }
      return null;
    },  
    mobileNumberValidation(){
      this.phone_error = "";
      this.phoneValue = this.form.mobile.replace(/[^0-9]+/g, "");
      this.form.mobile = this.phoneValue
        .replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "$1-$2-$3")
        .slice(0, 12);     
    },
    createTerms() {
      this.$modal.show(
        CreateTerm,
        {
          supplier_id: this.$route.params.supplierId,
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "400px",
        }
      );
    },
    SelectCategory(currentObj) {
      this.form.category_id = currentObj.id;
      this.form.category_name = currentObj.label;
    },
    getAddressData: function (addressData) {
      this.form.postal_code = addressData.postal_code;
      var city_name = addressData.locality;
      if (city_name != null) {
        this.getCityIdByName(city_name);
      }
      var state_prefix = addressData.administrative_area_level_1;
      if (state_prefix != null) {
        this.getStateByStatePrefix(state_prefix);
      }
      console.log(addressData);
      this.form.street = addressData.street_number + " " + addressData.route;
      this.address = addressData;
    },
    getStateByStatePrefix(state_prefix) {
      getStateByPrefix(state_prefix).then((response) => {
        this.form.state_id = response.data.data.state_id;
      });
    },
    getCityIdByName(city_name) {
      getCityByName(city_name).then((response) => {
        const city_id = response.data.data.city_id;
        const stateId = response.data.data.state_id;
        getCityListByStateId(stateId).then((response) => {
          this.form.city_id = null;
          this.city_list = response.data.data;
          this.form.city_id = city_id;
          this.city_loading = false;
        });
      });
    },
    getCategory() {
      this.loading = true;
      getCategoryTree().then((response) => {
        this.category = response.data.data;
        this.loading = false;
      });
    },

    CreateCity() {
      this.$modal.show(
        CreateCity,
        { stateId: this.form.state_id },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    createSupplier: function () {
      this.loading = true;
      this.form
        .post(this.api_url)
        .then((response) => {
          if (response.status) {
            this.$router.push({ path: "/supplier/list" });
            this.loading = false;
            this.$showResponse("success", response.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getAllcategoryList() {
      this.loading = true;
      getAllcategoryList().then((response) => {
        this.category_list = response.data.data;
        this.loading = false;
      });
    },
    createProductId(categoryId) {
      let category_name = "";
      this.category_list.map((cateName) => {
        if (cateName.id == this.form.category_id) {
          category_name = cateName.label;
        }
      });
      this.form.category_name = category_name;
      //this.loading = true
      createProductId(categoryId).then((response) => {
        this.form.product_id = response.data;
        //this.loading = false
      });
    },
    checkZip(value) {
      return /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(value);
    },
    validPostal(postalcode) {
      this.form.errors.add("postal_code", "");
      let postal_code = postalcode.toUpperCase();
      if (this.checkZip(postal_code)) {
        this.form.postal_code = postal_code
          .replace(/\W/g, "")
          .replace(/(...)/, "$1 ");
      } else {
        this.form.errors.add("postal_code", "Invalid Postal Code.");
        const h = this.$createElement;
        this.$notify({
          message: h(
            "i",
            {
              style: "color: red",
            },
            "Invalid Postal Code"
          ),
          offset: 200,
          type: "warning",
        });

        this.form.postal_code = "";
      }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length <= 0) {
        return;
      }

      this.form.supplier_image = files[0];
      this.img_preview = URL.createObjectURL(files[0]);
    },
    dataURItoBlob(dataURI) {
      var mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var binary = atob(dataURI.split(",")[1]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: mime });
    },
    getCityListByStateId() {
      getCityListByStateId(this.form.state_id).then((response) => {
        this.city_list = response.data.data;
        this.form.city_id = "";
      });
    },
    getCityList() {
      this.loading = true;
      getCityList().then((response) => {
        this.city_list = response.data.data;
        this.loading = false;
      });
    },
    getStateList() {
      this.loading = true;
      getStateList().then((response) => {
        this.state_list = response.data.data;
        this.loading = false;
      });
    },
    getSupplierTerms() {
      this.loading = true;
      getSupplierTerms(this.$route.params.supplierId)
        .then((response) => {
          this.loading = false;
          this.terms = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.terms = [];
        });
    },
    handleNodeClick(node) {
      this.treeLabel = node.label;
      this.$refs.selectTree1.blur();
    },
    addCategorys() {
      let category_name = "";
      this.category_list.map((cateName) => {
        if (cateName.id == this.form.category_id) {
          category_name = cateName.label;
        }
      });
      this.$modal.show(
        addCategory,
        {
          parentId: this.form.category_id,
          category_action: "add",
          category_name: category_name,
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "600px",
        }
      );
    },
    generateSupplierId() {
      generateSupplierId().then((response) => {
        this.form.supplier_Id = response.data.data;
      });
    },
  },
  created() {
    this.getAllcategoryList();
    globalEvents.$on("termAdded", (term) => {
      if (term) {
        this.terms.push(term);
        this.form.term_id = term.id;
      }
    });

    let supplierId = this.$route.params.supplierId;
    this.logo_btn_text = "Add Logo";
    if (parseInt(supplierId) > 0) {
      this.logo_btn_text = "Update Logo";
      this.submit_btn_text = "Update Supplier";
      this.title_text = "Update Supplier";
      this.action = "update";
      this.response_msg = "Supplier updated successfully.";

      getSupplierDetails(supplierId).then((response) => {
        this.api_url = "supplier/update/" + supplierId;
        this.form = new Form(response.data.data);
        console.log(response.data.data, "response.data.data");
        //this.form.category_id = response.data.data.category.id;
        //this.expand = [response.data.data.category.id];
        this.treeLabel = response.data.data.category_label; // Selvan added
        if (response.data.data.supplier_image) {
          this.img_preview =
            process.env.VUE_APP_BASE_IMAGE_URL +
            response.data.data.supplier_image;
          this.image_btn = 1;
        }
      });
      this.getSupplierTerms();
    } else {
      //generate supplier id
      this.generateSupplierId();
    }
    this.getStateList();
    this.getCityList();
    this.getCategory();
  },
};
</script>

<style scoped>
.tree_option {
  overflow-y: scroll;
  height: auto;
  padding: 10px 10px;
}

.add_option {
  padding: 0px;
  overflow-y: unset;
}
</style>

